if (module.hot) {
  module.hot.accept();
}

global.jQuery = require("jquery");
const slick = require("slick-carousel");
const niceSelect = require("jquery-nice-select");
const $ = jQuery;

jQuery(function () {
  initNiceSelect();
  initSlickSlider();
  initAnchors();
  initOldScript();
  initScrollParallax();
  initStickyScrollBlock();
  initActive();
});

// function initVideoPlayer() {
// 	jQuery('.video').each(function() {
// 		var holder = jQuery(this);
// 		var btnPlay = holder.find('.btn-play');
// 		var video = holder.find('video');
// 		btnPlay.on('click', function(e) {
// 			e.preventDefault();
// 			holder.toggleClass('active');
// 			if (holder.hasClass('active')) {
// 				video.get(0).play();
// 				holder.addClass('show');
// 			}
// 		});

// 	});
// }

// initialize fixed blocks on scroll
function initStickyScrollBlock() {
  jQuery(".sidebar-list").stickyScrollBlock({
    setBoxHeight: true,
    activeClass: "fixed-position",
    container: ".main-block .container",
    positionType: "fixed",
    extraTop: function () {
      var totalHeight = 0;
      jQuery("0").each(function () {
        totalHeight += jQuery(this).outerHeight();
      });
      return totalHeight;
    },
  });

  jQuery(".navigation-block").stickyScrollBlock({
    setBoxHeight: true,
    activeClass: "fixed-position",
    container: ".main-block .container",
    positionType: "fixed",
    extraTop: function () {
      var totalHeight = 0;
      jQuery("0").each(function () {
        totalHeight += jQuery(this).outerHeight();
      });
      return totalHeight;
    },
  });
}

function initScrollParallax() {
  var win = jQuery(window);
  var winHeight;
  var frameTimer;
  var cTop = 0;
  var parallaxList = [];

  jQuery(".js-parallax-item").each(function () {
    addItem(jQuery(this));
  });

  resizeHandler();
  win.on("load resize", resizeHandler);
  frameTimer = requestAnimationFrame(requestFunction);

  frameTimer = requestAnimationFrame(requestFunction);
  win.on("load", resizeHandler);

  function requestFunction() {
    var winTop = win.scrollTop();
    var diff = winTop - cTop;
    if (diff > 0.5 || diff < -0.5) {
      cTop += 0.5 * diff;
      var timer;

      clearTimeout(timer);
      setTimeout(function () {
        moveParallax(cTop);
      }, 100);
    }
    if (frameTimer) frameTimer = requestAnimationFrame(requestFunction);
  }

  function addItem(item) {
    parallaxList.push({
      parallaxItem: item.find(".js-move-item"),
      item: item.addClass("parallax-inited"),
      itemBg: item.find(".bg-white"),
      max: item.data("parallax-max") || 120, // percents
      min: item.data("parallax-min") || 0,
      direction: item.data("direction") || "horizontal", // can be horizontal, vertical, scale
      infinite: item.data("infinite") || "infinite",
      offset: item.data("offset") || 0,
      itemBottom: item.attr("data-bottom") ? item.attr("data-bottom") : false,
    });
  }

  function resizeHandler() {
    winHeight = win.height();
    refreshParallaxData();
    scrollHandler();
  }

  function scrollHandler() {
    var winTop = win.scrollTop();
    var timer;
    clearTimeout(timer);
    setTimeout(function () {
      moveParallax(winTop);
    }, 100);
  }

  function refreshParallaxData() {
    parallaxList.forEach(function (el) {
      el.scrollSpace = (winHeight || win.height()) / 1;
      var parentHolder = el.item.parent();

      if (el.infinite === "infinite") {
        el.scrollSpace =
          el.startPoint > winHeight
            ? winHeight + parentHolder.height()
            : parentHolder.offset().top + parentHolder.height();
      } else {
        el.scrollSpace =
          el.startPoint > winHeight
            ? winHeight - el.offset
            : parentHolder.offset().top - el.offset;
      }

      if (el.itemBottom) {
        el.startPoint = el.item.offset().top - el.item.outerHeight(true);
      } else {
        el.startPoint = el.item.offset().top;
      }

      el.parallaxKoef = (el.max - el.min) / el.scrollSpace;

      scrollAnimate(el.item);
    });
  }

  function moveParallax(scrollPosition) {
    var winBottom = scrollPosition + winHeight;

    parallaxList.forEach(function (el) {
      var scrollDiff =
        el.startPoint > winHeight ? winBottom - el.startPoint : scrollPosition;
      scrollDiff = Math.min(Math.max(scrollDiff, 0), el.scrollSpace);
      var parallaxValue = scrollDiff * el.parallaxKoef + el.min;

      if (el.direction === "vertical") {
        el.parallaxItem.css({
          transform: "translate3d(0," + parallaxValue + "px, 0)",
        });
      }
      if (el.direction === "horizontal") {
        el.parallaxItem.css({
          transform: "translate3d(" + parallaxValue + "%, 0, 0)",
        });
      }
      if (el.direction === "scale") {
        el.parallaxItem.css({ transform: "scale(" + parallaxValue + ")" });
      }
    });
  }

  function scrollAnimate(item) {
    win.on("scroll", function () {
      if (win.scrollTop() + win.height() / 1.5 > item.offset().top) {
        item.addClass("in-viewport");
      }
    });
  }
}

function initOldScript() {
  function gotopPosition(a) {
    0 == a
      ? $("#go-top").fadeOut()
      : $("#go-top").is(":hidden") && $("#go-top").fadeIn(),
      a >= $("#footer").position().top - $(window).height()
        ? $("#go-top").css({
            position: "absolute",
            top: "-70px",
            bottom: "auto",
          })
        : "absolute" == $("#go-top").css("position") &&
          $("#go-top").css({ position: "fixed", top: "auto", bottom: "10px" });
  }
  var winWidth = window.innerWidth;
  $(document).ready(function () {
    $("#searchBtn").on("click", function () {
      $("#search-bar").is(":visible")
        ? $("#search-bar").slideUp()
        : $("#search-bar").slideDown();
    }),
      $(".hamburger").bind("click", function () {
        $("#header-ph .language .list-lan").is(":visible") &&
          $("#header-ph .language .list-lan").hide(),
          $(this).hasClass("is-active")
            ? ($(this).removeClass("is-active"), $("#header-list").slideUp())
            : ($(this).addClass("is-active"),
              $("#header-list .nav").hide(),
              $("#header-list").slideDown(),
              setTimeout(function () {
                var a = 300;
                $("#header-list .nav").each(function () {
                  $(this).fadeIn(a), (a += 60);
                });
              }, 300));
      }),
      $("#header-ph .language a").click(function () {
        $(".hamburger").hasClass("is-active")
          ? ($(".hamburger").removeClass("is-active"),
            $("#header-list").slideUp(function () {
              $("#header-ph .language .list-lan").show();
            }))
          : $(this).siblings(".list-lan").is(":visible")
          ? $(this).siblings(".list-lan").hide()
          : $(this).siblings(".list-lan").show();
      }),
      $("#header-list .nav .sub-m").parent(".nav").addClass("stay"),
      $("#header-list .nav .sub-m")
        .siblings("a")
        .attr("href", "javascript:void(0);"),
      $("#header-list .stay a").on("click", function () {
        $(this).siblings(".sub-m") &&
          ($(this).siblings(".sub-m").is(":visible")
            ? ($(this).siblings(".sub-m").slideUp(),
              $(this).parent(".nav").removeClass("stay_on"))
            : ($("#header-list .stay .sub-m:visible").slideUp(),
              $("#header-list .stay .sub-m:visible")
                .parent(".nav")
                .removeClass("stay_on"),
              $(this).siblings(".sub-m").slideDown(),
              $(this).parent(".nav").addClass("stay_on")));
      }),
      $("#go-top").click(function () {
        var a = $(
          window.opera
            ? "CSS1Compat" == document.compatMode
              ? "html"
              : "body"
            : "html,body"
        );
        a.animate({ scrollTop: 0 }, 300);
      });
  }),
    $(window).resize(function () {
      (winWidth = window.innerWidth),
        winWidth < 1023
          ? $("#search-bar").hide()
          : $("#header-list").is(":visible") &&
            ($(".hamburger").removeClass("is-active"),
            $("#header-list").hide(0));
    });
  var nowTop = $(window).scrollTop();
  $(window).scroll(function () {
    (nowTop = $(this).scrollTop()), gotopPosition(nowTop);
  });
  var style;
  jQuery("#gsc-i-id2").each(function () {
    style = jQuery(this).css("background");
  });

  jQuery("#gsc-i-id2").on("focus", function () {
    jQuery(this).css("background", "none");
  });

  jQuery("#gsc-i-id2").on("blur", function () {
    jQuery(this).css("background", style);
  });
}

function initNiceSelect() {
  jQuery("select").niceSelect();
}

function initSlickSlider() {
  jQuery(".slider").slick({
    slidesToScroll: 1,
    rows: 0,
    prevArrow:
      '<button class="slick-prev"><i class="icon icon-angle-left"></i></button>',
    nextArrow:
      '<button class="slick-next"><i class="icon icon-angle-right"></i></button>',
    dots: true,
    infinite: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });

  jQuery(".goods-slider").slick({
    slidesToScroll: 1,
    prevArrow:
      '<button class="slick-prev"><i class="icon icon-angle-left"></i></button>',
    nextArrow:
      '<button class="slick-next"><i class="icon icon-angle-right"></i></button>',
    mobileFirst: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });
}

// initialize smooth anchor links
function initAnchors() {
  var win = jQuery(window);
  var btnScroll = jQuery(".sidebar-list a");
  btnScroll.on("click", function (e) {
    e.preventDefault();
    var href = jQuery(this).attr("href");
    jQuery("body, html").animate(
      {
        scrollTop: jQuery(href).offset().top,
      },
      600
    );
  });
  var sections = [];

  btnScroll.each(function (i) {
    var section = jQuery(jQuery(this).attr("href"));
    sections.push(section);
  });

  win.on("scroll", function () {
    var scrollTop = win.scrollTop();
    for (i = 0; i < sections.length; i++) {
      if (scrollTop + 1 >= sections[i].offset().top) {
        btnScroll
          .eq(i)
          .closest("li")
          .addClass("active")
          .siblings("li")
          .removeClass("active");
      }
    }
  });
}

function initActive() {
  var win = jQuery(window);
  var btnScroll = jQuery(".navigation a");
  var navigationBlockHeight = jQuery(".navigation-block").outerHeight();
  btnScroll.on("click", function (e) {
    e.preventDefault();
    var href = jQuery(this).attr("href");
    jQuery("body, html").animate(
      {
        scrollTop: jQuery(href).offset().top - navigationBlockHeight,
      },
      600
    );
  });
  var sections = [];

  btnScroll.each(function (i) {
    var section = jQuery(jQuery(this).attr("href"));
    sections.push(section);
  });

  win.on("scroll", function () {
    var scrollTop = win.scrollTop();
    for (i = 0; i < sections.length; i++) {
      if (scrollTop + 1 >= sections[i].offset().top - navigationBlockHeight) {
        btnScroll
          .eq(i)
          .closest("li")
          .addClass("active")
          .siblings("li")
          .removeClass("active");
      }
    }
  });
}

/*
 * jQuery sticky box plugin
 */
(function ($, $win) {
  "use strict";

  function StickyScrollBlock($stickyBox, options) {
    this.options = options;
    this.$stickyBox = $stickyBox;
    this.init();
  }

  var StickyScrollBlockPrototype = {
    init: function () {
      this.findElements();
      this.attachEvents();
      this.makeCallback("onInit");
    },

    findElements: function () {
      // find parent container in which will be box move
      this.$container = this.$stickyBox.closest(this.options.container);
      // define box wrap flag
      this.isWrap =
        this.options.positionType === "fixed" && this.options.setBoxHeight;
      // define box move flag
      this.moveInContainer = !!this.$container.length;
      // wrapping box to set place in content
      if (this.isWrap) {
        this.$stickyBoxWrap = this.$stickyBox
          .wrap('<div class="' + this.getWrapClass() + '"/>')
          .parent();
      }
      //define block to add active class
      this.parentForActive = this.getParentForActive();
      this.isInit = true;
    },

    attachEvents: function () {
      var self = this;

      // bind events
      this.onResize = function () {
        if (!self.isInit) return;
        self.resetState();
        self.recalculateOffsets();
        self.checkStickyPermission();
        self.scrollHandler();
      };

      this.onScroll = function () {
        self.scrollHandler();
      };

      // initial handler call
      this.onResize();

      // handle events
      $win
        .on("load resize orientationchange", this.onResize)
        .on("scroll", this.onScroll);
    },

    defineExtraTop: function () {
      // define box's extra top dimension
      var extraTop;

      if (typeof this.options.extraTop === "number") {
        extraTop = this.options.extraTop;
      } else if (typeof this.options.extraTop === "function") {
        extraTop = this.options.extraTop();
      }

      this.extraTop =
        this.options.positionType === "absolute"
          ? extraTop
          : Math.min(this.winParams.height - this.data.boxFullHeight, extraTop);
    },

    checkStickyPermission: function () {
      // check the permission to set sticky
      this.isStickyEnabled = this.moveInContainer
        ? this.data.containerOffsetTop + this.data.containerHeight >
          this.data.boxFullHeight +
            this.data.boxOffsetTop +
            this.options.extraBottom
        : true;
    },

    getParentForActive: function () {
      if (this.isWrap) {
        return this.$stickyBoxWrap;
      }

      if (this.$container.length) {
        return this.$container;
      }

      return this.$stickyBox;
    },

    getWrapClass: function () {
      // get set of container classes
      try {
        return this.$stickyBox
          .attr("class")
          .split(" ")
          .map(function (name) {
            return "sticky-wrap-" + name;
          })
          .join(" ");
      } catch (err) {
        return "sticky-wrap";
      }
    },

    resetState: function () {
      // reset dimensions and state
      this.stickyFlag = false;
      this.$stickyBox
        .css({
          "-webkit-transition": "",
          "-webkit-transform": "",
          transition: "",
          transform: "",
          position: "",
          width: "",
          left: "",
          top: "",
        })
        .removeClass(this.options.activeClass);

      if (this.isWrap) {
        this.$stickyBoxWrap
          .removeClass(this.options.activeClass)
          .removeAttr("style");
      }

      if (this.moveInContainer) {
        this.$container.removeClass(this.options.activeClass);
      }
    },

    recalculateOffsets: function () {
      // define box and container dimensions
      this.winParams = this.getWindowParams();

      this.data = $.extend(this.getBoxOffsets(), this.getContainerOffsets());

      this.defineExtraTop();
    },

    getBoxOffsets: function () {
      function offetTop(obj) {
        obj.top = 0;
        return obj;
      }
      var boxOffset =
        this.$stickyBox.css("position") === "fixed"
          ? offetTop(this.$stickyBox.offset())
          : this.$stickyBox.offset();
      var boxPosition = this.$stickyBox.position();

      return {
        // sticky box offsets
        boxOffsetLeft: boxOffset.left,
        boxOffsetTop: boxOffset.top,
        // sticky box positions
        boxTopPosition: boxPosition.top,
        boxLeftPosition: boxPosition.left,
        // sticky box width/height
        boxFullHeight: this.$stickyBox.outerHeight(true),
        boxHeight: this.$stickyBox.outerHeight(),
        boxWidth: this.$stickyBox.outerWidth(),
      };
    },

    getContainerOffsets: function () {
      var containerOffset = this.moveInContainer
        ? this.$container.offset()
        : null;

      return containerOffset
        ? {
            // container offsets
            containerOffsetLeft: containerOffset.left,
            containerOffsetTop: containerOffset.top,
            // container height
            containerHeight: this.$container.outerHeight(),
          }
        : {};
    },

    getWindowParams: function () {
      return {
        height: window.innerHeight || document.documentElement.clientHeight,
      };
    },

    makeCallback: function (name) {
      if (typeof this.options[name] === "function") {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        this.options[name].apply(this, args);
      }
    },

    destroy: function () {
      this.isInit = false;
      // remove event handlers and styles
      $win
        .off("load resize orientationchange", this.onResize)
        .off("scroll", this.onScroll);
      this.resetState();
      this.$stickyBox.removeData("StickyScrollBlock");
      if (this.isWrap) {
        this.$stickyBox.unwrap();
      }
      this.makeCallback("onDestroy");
    },
  };

  var stickyMethods = {
    fixed: {
      scrollHandler: function () {
        this.winScrollTop = $win.scrollTop();
        var isActiveSticky =
          this.winScrollTop -
            (this.options.showAfterScrolled ? this.extraTop : 0) -
            (this.options.showAfterScrolled
              ? this.data.boxHeight + this.extraTop
              : 0) >
          this.data.boxOffsetTop - this.extraTop;

        if (isActiveSticky) {
          this.isStickyEnabled && this.stickyOn();
        } else {
          this.stickyOff();
        }
      },

      stickyOn: function () {
        if (!this.stickyFlag) {
          this.stickyFlag = true;
          this.parentForActive.addClass(this.options.activeClass);
          this.$stickyBox.css({
            width: this.data.boxWidth,
            position: this.options.positionType,
          });
          if (this.isWrap) {
            this.$stickyBoxWrap.css({
              height: this.data.boxFullHeight,
            });
          }
          this.makeCallback("fixedOn");
        }
        this.setDynamicPosition();
      },

      stickyOff: function () {
        if (this.stickyFlag) {
          this.stickyFlag = false;
          this.resetState();
          this.makeCallback("fixedOff");
        }
      },

      setDynamicPosition: function () {
        this.$stickyBox.css({
          top: this.getTopPosition(),
          left: this.data.boxOffsetLeft - $win.scrollLeft(),
        });
      },

      getTopPosition: function () {
        if (this.moveInContainer) {
          var currScrollTop =
            this.winScrollTop + this.data.boxHeight + this.options.extraBottom;

          return Math.min(
            this.extraTop,
            this.data.containerHeight +
              this.data.containerOffsetTop -
              currScrollTop
          );
        } else {
          return this.extraTop;
        }
      },
    },
    absolute: {
      scrollHandler: function () {
        this.winScrollTop = $win.scrollTop();
        var isActiveSticky =
          this.winScrollTop > this.data.boxOffsetTop - this.extraTop;

        if (isActiveSticky) {
          this.isStickyEnabled && this.stickyOn();
        } else {
          this.stickyOff();
        }
      },

      stickyOn: function () {
        if (!this.stickyFlag) {
          this.stickyFlag = true;
          this.parentForActive.addClass(this.options.activeClass);
          this.$stickyBox.css({
            width: this.data.boxWidth,
            transition: "transform " + this.options.animSpeed + "s ease",
            "-webkit-transition":
              "transform " + this.options.animSpeed + "s ease",
          });

          if (this.isWrap) {
            this.$stickyBoxWrap.css({
              height: this.data.boxFullHeight,
            });
          }

          this.makeCallback("fixedOn");
        }

        this.clearTimer();
        this.timer = setTimeout(
          function () {
            this.setDynamicPosition();
          }.bind(this),
          this.options.animDelay * 1000
        );
      },

      stickyOff: function () {
        if (this.stickyFlag) {
          this.clearTimer();
          this.stickyFlag = false;

          this.timer = setTimeout(
            function () {
              this.setDynamicPosition();
              setTimeout(
                function () {
                  this.resetState();
                }.bind(this),
                this.options.animSpeed * 1000
              );
            }.bind(this),
            this.options.animDelay * 1000
          );
          this.makeCallback("fixedOff");
        }
      },

      clearTimer: function () {
        clearTimeout(this.timer);
      },

      setDynamicPosition: function () {
        var topPosition = Math.max(0, this.getTopPosition());

        this.$stickyBox.css({
          transform: "translateY(" + topPosition + "px)",
          "-webkit-transform": "translateY(" + topPosition + "px)",
        });
      },

      getTopPosition: function () {
        var currTopPosition =
          this.winScrollTop - this.data.boxOffsetTop + this.extraTop;

        if (this.moveInContainer) {
          var currScrollTop =
            this.winScrollTop + this.data.boxHeight + this.options.extraBottom;
          var diffOffset = Math.abs(
            Math.min(
              0,
              this.data.containerHeight +
                this.data.containerOffsetTop -
                currScrollTop -
                this.extraTop
            )
          );

          return currTopPosition - diffOffset;
        } else {
          return currTopPosition;
        }
      },
    },
  };

  // jQuery plugin interface
  $.fn.stickyScrollBlock = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    var options = $.extend(
      {
        container: null,
        positionType: "fixed", // 'fixed' or 'absolute'
        activeClass: "fixed-position",
        setBoxHeight: true,
        showAfterScrolled: false,
        extraTop: 0,
        extraBottom: 0,
        animDelay: 0.1,
        animSpeed: 0.2,
      },
      opt
    );

    return this.each(function () {
      var $stickyBox = jQuery(this);
      var instance = $stickyBox.data("StickyScrollBlock");

      if (typeof opt === "object" || typeof opt === "undefined") {
        StickyScrollBlock.prototype = $.extend(
          stickyMethods[options.positionType],
          StickyScrollBlockPrototype
        );
        $stickyBox.data(
          "StickyScrollBlock",
          new StickyScrollBlock($stickyBox, options)
        );
      } else if (typeof method === "string" && instance) {
        if (typeof instance[method] === "function") {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };

  // module exports
  window.StickyScrollBlock = StickyScrollBlock;
})(jQuery, jQuery(window));
